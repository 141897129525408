import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthService, DialogService, StorageService } from '@core/services';
import { IdentityService } from '@core/services/identity.service';
import { MonacoEditorService } from '@core/services/monaco-editor.service';
import { environment } from '@env';
import { Subject, takeUntil } from 'rxjs';
import { AboutDialogComponent, AboutDialogData } from './about-dialog/about-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Artemis Developer Portal';
  authenticated = false;
  enableRoute = true;
  name?: string;
  email?: string;
  showAccount = false;
  canViewMembers = false;
  canViewWorkspaces = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private identityService: IdentityService,
    private monacoEditorService: MonacoEditorService,
    private msalService: MsalService,
    private dialogService: DialogService,
    private readonly router: Router,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.monacoEditorService.load();
    this.identityService.claimsChanged$
      .pipe(
        takeUntil(this._destroying$))
      .subscribe(claims => {
        this.authenticated = this.identityService.isAuthenticated;
        if (this.authenticated) {
          this.canViewMembers = this.identityService.isSuperAdmin;
          this.canViewWorkspaces = this.identityService.isSuperAdmin
            || this.identityService.isAdmin;
          this.name = claims.name;
          this.email = claims.email;
        } else {
          this.canViewMembers = false;
          this.canViewWorkspaces = false;
          this.name = undefined;
          this.email = undefined;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  onNavigateToWorkspaces() {
    this.storageService.set('viewingBin', false);
    this.router.navigate(['/workspaces']);
  }

  onSwitchAccount() { // Add log out function here
    this.authService.logout();
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  }

  onToggleAccount(e: Event) {
    e.stopPropagation();
    if (this.authenticated) {
      this.showAccount = !this.showAccount;
    } else {
      this.login();
    }
  }

  onCloseAccount() {
    this.showAccount = false;
  }

  onAbout() {
    this.dialogService.show(AboutDialogComponent, {
      width: '650px',
      data: new AboutDialogData(
        this.authenticated, 'Portal',
        environment.buildId, environment.buildNumber,
        environment.pipeline, environment.branch,
        environment.stage, environment.repository, environment.commit,
        environment.environmentId, environment.environmentName)
    });
  }
  
  private login(): void {
    this.msalService.loginRedirect();
  }

}
