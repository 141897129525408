import { Pipe, PipeTransform } from '@angular/core';
import { PopulateService } from '@workspaces/services';

@Pipe({
  name: 'workspaceTemplateName',
})
export class WorkspaceTemplateNamePipe implements PipeTransform {
  constructor(private populateService: PopulateService) {}

  transform(templateId: string): string {
    if (templateId === 'audit') {
      return 'Smart Audit 1.0';
    }

    if (templateId === 'cmp') {
      return 'CMP';
    }

    if (templateId === 'smartAuditV2') {
      return 'Plus Training';
    }

    if (templateId === 'takeaway') {
      return 'Plus Basic';
    }

    const templates = this.populateService.getTemplates();
    const template = templates.find((t) => t.id === templateId);
    return template ? template.name : templateId;
  }
}
