<mat-toolbar color="primary" class="appbar mat-elevation-z4" (click)="onCloseAccount()">
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon class="material-symbols-outlined">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [routerLink]="['']">
      <mat-icon class="material-symbols-outlined">home</mat-icon>
      <span>Home</span>
    </button>
    <div *ngIf="authenticated">
      <button mat-menu-item [routerLink]="['members']" *ngIf="canViewMembers">
        <mat-icon class="material-symbols-outlined">group</mat-icon>
        <span>Members</span>
      </button>
      <button mat-menu-item (click)="onNavigateToWorkspaces()" *ngIf="canViewWorkspaces">
        <mat-icon class="material-symbols-outlined">workspaces</mat-icon>
        <span>Workspaces</span>
      </button>
      <mat-divider></mat-divider>
    </div>
    <button mat-menu-item (click)="onAbout()">
      <mat-icon class="material-symbols-outlined">info</mat-icon>
      <span>About</span>
    </button>
  </mat-menu>
  <span>{{ title }}</span>
  <div class="spacer"></div>
  <button mat-icon-button *ngIf="authenticated" (click)="onToggleAccount($event)">
    <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
  </button>
</mat-toolbar>
<div *ngIf="authenticated && showAccount">
  <mat-card class="account mat-elevation-z6">
    <mat-card-content>
      <p class="name">{{name}}</p>
      <p class="email">{{email}}</p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="onSwitchAccount()">Switch Account</button>
    </mat-card-actions>
  </mat-card>
</div>
<main (click)="onCloseAccount()">
  <router-outlet></router-outlet>
</main>