import { Injectable } from '@angular/core';
import { MemberContent, RecordContent, SecurityEntity } from '@core/models';
import { RecordTypes } from '@shared/reference';
import { Observable, catchError, forkJoin, mergeMap, of, switchMap } from 'rxjs';
import { DocumentApiService } from './document-api.service';

@Injectable({
  providedIn: 'root'
})
export class UserMemberService {

  constructor(private documentApiService: DocumentApiService) {
  }

  linkMemberToPerson(workspaceId: string,
    member: SecurityEntity<MemberContent>,
    workspacePolicy: string): void {
    this.documentApiService.getRecordsOfType(RecordTypes.person, workspaceId)
      .pipe(
        switchMap(persons => {
          let person = persons.find(p => p.fields['memberId'] === member.id);
          if (!person) {
            person = persons.find(p => p.fields['email'] === member.content.email);
          }

          if (person) {
            person.fields['state'] = 'active';
            person.fields['memberId'] = workspacePolicy ? member.id : null;
            person.fields['workspacePolicy'] = workspacePolicy;
            person.fields['displayName'] = member.content.name;
            return this.documentApiService.updateRecord(person.id, workspaceId, person);
          } else {
            // Create and activate a new person
            return this.createAndActivatePersonRecord(workspaceId, workspacePolicy, member);
          }
        })
      )
      .subscribe();
  }

  createAndActivatePersonRecord(workspaceId: string,
    workspacePolicy: string,
    member: SecurityEntity<MemberContent>) {
    const newPerson: RecordContent = {
      recordType: RecordTypes.person,
      fields: {
        subType: RecordTypes.person,
        state: 'active',
        email: member.content.email,
        fullName: member.content.name,
        displayName: member.content.name,
        workspacePolicy: workspacePolicy,
        memberId: member.id
      },
      links: {}
    }
    return forkJoin({
      person: this.documentApiService.createRecord(newPerson,
        workspaceId)
    });
  }

  unLinkMemberFromPerson(workspaceId: string, memberId: string): void {
    this.documentApiService.getRecordsOfType(
      RecordTypes.person,
      workspaceId)
      .subscribe(persons => {
        if (persons.length > 0) {
          let person = persons.find(p => p.fields['memberId'] === memberId);
          if (person) {
            person.fields['memberId'] = null;
            person.fields['workspacePolicy'] = '';
            this.documentApiService.updateRecord(person.id, workspaceId, person).subscribe();
          }
        }
      });
  }

  createDefaultLocations(workspaceId: string, workspaceTemplateId: string): Observable<Boolean> {

    if (workspaceTemplateId === 'automation' || workspaceTemplateId === 'smartAuditV2') {

      const createCalls: Observable<any>[] = [];

      const locations = ['London', 'Mumbai', 'Hong Kong', 'Johor', 'Haarlemweg', 'Singapore'];

      for (const location of locations) {
        const locationRecord: RecordContent = {
          recordType: RecordTypes.location,
          fields: {
            subType: RecordTypes.location,
            state: 'draft',
            name: location,
            description: '-',
            postalCode: '-'
          },
          links: {}
        };
        createCalls.push(this.documentApiService.createRecord(locationRecord, workspaceId))
      }

      return forkJoin(createCalls).pipe(
        mergeMap(() => {
          console.log('default locations created');
          return of(true);
        }),
        catchError(e => {
          console.error(e);
          return of(false);
        }));
    }
    return of(true);
  }

}
