import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';

import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { environment } from '@env';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1
  || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [],
  imports: [
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        // Application (client) ID from the app registration
        clientId: environment.clientId,
        // The Azure cloud instance and the app's sign-in audience (workspace ID, common,
        // organizations, or consumers)
        authority:
          environment.authority,
        knownAuthorities: environment.knownAuthorities,
        // Indicates the page to navigate after logout.
        postLogoutRedirectUri: '/',
        // If "true", will navigate back to the original request location before processing the
        // auth code response.
        navigateToLoginRequestUrl: true,
        // This is your redirect URI
        redirectUri: environment.redirectUrl,
      },
      cache: {
        cacheLocation: 'localStorage',
        // Set to true for Internet Explorer 11
        storeAuthStateInCookie: isIE,
      },
      system: {
        loggerOptions: {
          loggerCallback(LogLevel, message: string) {
            console.log(`Auth: ${LogLevel}: ${message}`);
          },
          logLevel: LogLevel.Error,
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect,
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        [
          environment.authApiBaseUrl,
          [environment.authApiScope]
        ],
      ])
    }),
  ],
  exports: [
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    // MsalGuard
  ],
  bootstrap: []
})

export class AuthModule { }
