import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { Claims, WorkspaceClaim } from '@core/models';


@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private readonly emptyClaims: Claims = {
    id: '',
    email: '',
    name: '',
    policy: '',
    workspaces: [],
  };

  private claimsChanged: BehaviorSubject<Claims>
    = new BehaviorSubject<Claims>(this.emptyClaims);
  readonly claimsChanged$ = this.claimsChanged.asObservable();

  claims: Claims = this.emptyClaims;
  isAuthenticated: boolean = false;

  constructor(
    private authApiService: AuthApiService,
    private authService: AuthService) {

    this.authService.isAuthenticated$.subscribe(loggedIn => {
      if (loggedIn) {
        this.authApiService.me().subscribe({
          next: (response: Claims) => {
            this.isAuthenticated = true;
            this.updateClaims(response);
          },
          error: () => {
            this.isAuthenticated = false;
            this.updateClaims(this.emptyClaims);
          }
        });
      } else {
        this.isAuthenticated = false;
        this.updateClaims(this.emptyClaims);
      }
    });
  }

  get isSuperAdmin(): boolean {
    return this.claims.policy === 'superadmin';
  }

  get isAdmin(): boolean {
    return this.claims.policy === 'admin';
  }

  get isStandard(): boolean {
    return this.claims.policy === 'standard';
  }

  get isReadOnly(): boolean {
    return this.claims.policy === 'readonly';
  }

  get hasNoPolicy(): boolean {
    return this.claims.policy !== 'superadmin'
      && this.claims.policy !== 'admin'
      && this.claims.policy !== 'standard'
      && this.claims.policy !== 'readonly';
  }

  workspacePolicy(workspaceId: string): string {
    const workspace = this.claims.workspaces.find(w => w.id === workspaceId);
    return workspace ? workspace.workspacePolicy : '';
  }

  requireAuthentication(): void {
    if (!this.isAuthenticated) {
      this.authService.requireAuthentication();
    }
  }

  private updateClaims(claims: Claims): void {
    if (JSON.stringify(this.claims) !== JSON.stringify(claims)) {
      this.claims = {
        id: claims.id,
        email: claims.email,
        name: claims.name,
        policy: claims.policy,
        workspaces: claims.workspaces 
      };
      this.claimsChanged.next(this.claims);
    }
  }
}
