import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@env';

import { AccessTokenResponse, Claims, ServiceVersion } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  key: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  loggedIn = false;

  constructor(private http: HttpClient) { }

  getVersion(): Observable<ServiceVersion> {
    return this.http.get<ServiceVersion>(environment.authApiBaseUrl + '/health/version');
  }

  me(): Observable<Claims> {
    return this.http.get<Claims>(environment.authApiBaseUrl + '/auth/me');
  }

  getToken(grantType: string, refreshToken?: string): Observable<AccessTokenResponse> {
    const formData = new FormData();
    let headers = {};
    formData.append('grant_type', grantType);
    if (grantType === 'refresh_token' && refreshToken) {
      formData.append('refresh_token', refreshToken);
    }
    return this.http.post<AccessTokenResponse>(
      environment.authApiBaseUrl + '/auth/token',
      formData,
      { headers });
  }

  revoke(): Observable<boolean> {
    return this.http.post<boolean>(environment.authApiBaseUrl + '/auth/revoke', '');
  }
}
