import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum StorageKey {
  accessToken = 'access_token',
  expires = 'expires',
  refreshToken = 'refresh_token',
  tokenType = 'token_type',
};

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  localStorage: Storage = window.localStorage;
  itemChanged$ = new Subject<{ kind: string, key: string, value: string }>();

  clear(): void {
    if (this.isLocalStorageSupported) {
      localStorage.clear();
    }
  }

  set(key: string, value: any): void {
    if (this.isLocalStorageSupported) {
      localStorage.setItem(key, JSON.stringify(value));
      this.itemChanged$.next({ kind: 'set', key, value });
    }
  }

  get(key: string): any | null {
    if (this.isLocalStorageSupported) {
      try {
        return JSON.parse(localStorage.getItem(key)!);
      }
      catch (e) {
        console.error(`Error getting item ${key} from localStorage`, e);
        return null;
      }
    }
    return null;
  }

  remove(key: string): void {
    if (this.isLocalStorageSupported) {
      localStorage.removeItem(key);
      this.itemChanged$.next({ kind: 'remove', key, value: '' });
    }
  }

  private get isLocalStorageSupported(): boolean {
    return !!this.localStorage;
  }
}
