import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';

import { AccessTokenResponse } from '@core/models';
import { AuthApiService, StorageKey, StorageService } from '@core/services';

import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isAuthenticated$ = this._isAuthenticated.asObservable();

  private _isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isRefreshing$ = this._isRefreshing.asObservable();
  isRefreshing: boolean = false;

  constructor(
    private authApi: AuthApiService,
    private storageService: StorageService) {
    this._isAuthenticated.next(this.hasValidToken);
   }

  get hasValidToken(): boolean {
    return Date.now() < this.Expiration;
  }

  get hasRefreshToken(): boolean {
    return !!this.storageService.get(StorageKey.refreshToken);
  }

  login(): void {
    this.authApi.getToken('client_credentials').subscribe({
      next: (response: AccessTokenResponse) => {
        this.setSession(response);
        this._isAuthenticated.next(this.hasValidToken);
      },
      error: () => {
        this.logout();
      }
    });
  }

  logout(): void {
    this.removeSession();
    this._isAuthenticated.next(false);
  }

  forceRefreshOfToken(): void {
    const refreshToken = this.storageService.get(StorageKey.refreshToken)!;
    this.authApi
      .getToken('refresh_token', refreshToken)
      .subscribe({
        next: (response: AccessTokenResponse) => {
          this.setSession(response);
          this._isAuthenticated.next(this.hasValidToken);
        },
        error: () => {
          this.logout();
        },
      });
  }

  forceRefreshOfTokenAsync(): Promise<void> {
    const refreshToken = this.storageService.get(StorageKey.refreshToken)!;
    return new Promise<void>((resolve, reject) => {
        const refreshTokenSubscription = this.authApi
            .getToken('refresh_token', refreshToken)
            .subscribe({
                next: (response: AccessTokenResponse) => {
                    this.setSession(response);
                    this._isAuthenticated.next(this.hasValidToken);
                    refreshTokenSubscription.unsubscribe();
                    resolve();
                },
                error: (error) => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        // Retry with client_credentials if refresh token fails with 401
                        const clientCredentialsSubscription = this.authApi
                            .getToken('client_credentials')
                            .subscribe({
                                next: (response: AccessTokenResponse) => {
                                    this.setSession(response);
                                    this._isAuthenticated.next(this.hasValidToken);
                                    clientCredentialsSubscription.unsubscribe();
                                    resolve();
                                },
                                error: () => {
                                    this.logout();
                                    clientCredentialsSubscription.unsubscribe();
                                    reject();
                                }
                            });
                    } else {
                        // Pass through other errors
                        this.logout();
                        reject();
                    }
                    refreshTokenSubscription.unsubscribe();
                },
            });
    });
}


  refreshTokenAndAuthorize(
    request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this._isRefreshing.next(true);

      const refreshToken = this.storageService.get(StorageKey.refreshToken)!;
      // First try with our refresh token
      return this.authApi.getToken('refresh_token', refreshToken).pipe(
        switchMap((response: AccessTokenResponse) => {
          this.setSession(response);
          this.isRefreshing = false;
          this._isRefreshing.next(false);
          return next.handle(this.authorize(request));
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            // If the refresh token has expired, try with the client_credentials
            return this.authApi.getToken('client_credentials').pipe(
              switchMap((response: AccessTokenResponse) => {
                this.setSession(response);
                this.isRefreshing = false;
                this._isRefreshing.next(false);
                return next.handle(this.authorize(request));
              }));
          } else {
            // If we get an error other than 401, just pass it through
            this.isRefreshing = false;
            this._isRefreshing.next(false);
            return throwError(() => error);
          }
        }),
      );
    } else {
      return this.isRefreshing$.pipe(
        filter(refreshing => !refreshing),
        take(1),
        switchMap(() => next.handle(this.authorize(request)))
      );
    }
  }

  authorize(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken: string = this.storageService.get(StorageKey.accessToken)!;
    const tokenType: string = this.storageService.get(StorageKey.tokenType)!;

    if (accessToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', tokenType + ' ' + accessToken)
      });
    }

    return request;
  }

  requireAuthentication(): void {
    if (!this.hasValidToken) {
      this.login();
    }
  }

  private setSession(response: AccessTokenResponse) {
    // Set expirection time and subtract one minute to give a buffer
    const expires_in = response.expires_in;
    this.storageService.set(StorageKey.expires, Date.now() + (expires_in - 60) * 1000);

    this.storageService.set(StorageKey.accessToken, response.access_token);
    this.storageService.set(StorageKey.tokenType, response.token_type);
    this.storageService.set(StorageKey.refreshToken, response.refresh_token);
  }

  private removeSession(): void {
    this.storageService.remove(StorageKey.expires);
    this.storageService.remove(StorageKey.accessToken);
    this.storageService.remove(StorageKey.tokenType);
    this.storageService.remove(StorageKey.refreshToken);
  }

  private get Expiration(): number {
    const expiration = this.storageService.get(StorageKey.expires);
    // Use now as expiration if expiration is null
    return JSON.parse(expiration || Date.now().toString());
  }
}
