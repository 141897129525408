<h1 mat-dialog-title>About</h1>
<div mat-dialog-content class="flex-container">
  <div class="d-flex">
    <div class="set-width"><strong>{{ data.name }}:</strong></div>
    <div>
      <button mat-button tabindex="-1" (click)="openBuild(data.buildId)" [disabled]="isProduction">
        {{ data.buildNumber }} — {{ data.stage }}
      </button>
      <button mat-button tabindex="-1" (click)="openEnvironment(data.environmentId)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/pipelines.png" alt="pipeline" width="15" height="15"/>
      </button><br>
      <button mat-button tabindex="-1" (click)="openRepo(data.repository)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/git.png" alt="repo" width="15" height="15"/>
        {{ data.repository }}
      </button>
      <button mat-button tabindex="-1" (click)="openBranch(data.repository, data.branch)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/branch.png" alt="branch" width="15" height="15"/>
        {{ data.branch | branchName }}
      </button>
      <button mat-button tabindex="-1" (click)="openCommit(data.repository, data.commit)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/commit.png" alt="commit" width="15" height="15"/>
        {{ data.commit | leftString:8 }}
      </button>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="d-flex" *ngIf="adminApiVersion">
    <div class="set-width"><strong>Admin API:</strong></div>
    <div >
      <button mat-button tabindex="-1" (click)="openBuild(adminApiVersion.buildId)">
        {{ adminApiVersion.buildNumber }} — {{ adminApiVersion.stage }}
      </button>
      <button mat-button tabindex="-1" (click)="openEnvironment(adminApiVersion.environmentId)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/pipelines.png" alt="pipelines" width="15" height="15"/>
      </button><br>
      <button mat-button tabindex="-1" (click)="openRepo(adminApiVersion.repository)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/git.png" alt="repo" width="15" height="15"/>
        {{ adminApiVersion.repository }}
      </button>
      <button mat-button tabindex="-1"
        (click)="openBranch(adminApiVersion.repository, adminApiVersion.branch)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/branch.png" alt="branch" width="15" height="15"/>
        {{ adminApiVersion.branch | branchName }}
      </button>
      <button mat-button tabindex="-1"
        (click)="openCommit(adminApiVersion.repository, adminApiVersion.commit)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/commit.png" alt="commit" width="15" height="15" />
        {{ adminApiVersion.commit | leftString:8 }}
      </button>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="d-flex" *ngIf="documentApiVersion">
    <div class="set-width" ><strong>Document API:</strong></div>
    <div >
      <button mat-button tabindex="-1" (click)="openBuild(documentApiVersion.buildId)">
        {{ documentApiVersion.buildNumber }} — {{ documentApiVersion.stage }}
      </button>
      <button mat-button tabindex="-1" (click)="openEnvironment(documentApiVersion.environmentId)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/pipelines.png" alt="pipelines" width="15" height="15"/>
      </button><br>
      <button mat-button tabindex="-1" (click)="openRepo(documentApiVersion.repository)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/git.png" alt="repo" width="15" height="15"/>
        {{ documentApiVersion.repository }}
      </button>
      <button mat-button tabindex="-1"
        (click)="openBranch(documentApiVersion.repository, documentApiVersion.branch)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/branch.png" alt="branch" width="15" height="15"/>
        {{ documentApiVersion.branch | branchName }}
      </button>
      <button mat-button tabindex="-1"
        (click)="openCommit(documentApiVersion.repository, documentApiVersion.commit)"
              *ngIf="!isProduction">
        <img class="icon" ngSrc="/assets/commit.png" alt="commit" width="15" height="15" />
        {{ documentApiVersion.commit | leftString:8 }}
      </button>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">OK</button>
</div>
