import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env';
import {
  Document, RecordContent, RecordDocument, RecordTypeContent, ReferenceDataContent, ServiceVersion,
  TranslationContent
} from '../models';
import { DocumentTypes } from '@shared/reference';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {

  constructor(private http: HttpClient) { }

  getVersion(): Observable<ServiceVersion> {
    return this.http.get<ServiceVersion>(environment.documentApiBaseUrl + '/health/version');
  }

  //
  // Record Types
  //

  createRecordType(
    recordType: RecordTypeContent,
    workSpaceId: string): Observable<RecordTypeContent> {

    return this.http.post<RecordTypeContent>(
      environment.documentApiBaseUrl + `/${workSpaceId}/recordTypes`, recordType);
  }

  deleteRecordType(
    recordType: RecordTypeContent,
    workspaceId: string): Observable<RecordTypeContent> {
    const headers = new HttpHeaders()
      .set('If-Match', recordType.eTag ?? '');

    return this.http.delete<RecordTypeContent>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordType.recordType}`,
      { headers: headers });
  }

  getAllRecordTypes(workspaceId: string): Observable<RecordTypeContent[]> {
    return this.http.get<RecordTypeContent[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes`);
  }

  getRecordType(recordTypeName: string, workspaceId: string): Observable<RecordTypeContent> {

    return this.http.get<RecordTypeContent>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordTypeName}`);
  }

  updateRecordType(
    recordType: RecordTypeContent,
    workspaceId: string): Observable<RecordTypeContent> {

    return this.http.put<RecordTypeContent>(
      environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordType.recordType}`,
      recordType);
  }

  //
  // Documents
  //

  createDocument(document: Document, workspaceId: string): Observable<Document> {

    return this.http.post<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/`, document);
  }

  deleteDocument(document: Document, workspaceId: string): Observable<Document> {

    const headers = new HttpHeaders()
      .set('If-Match', document._etag ?? '');

    return this.http.delete<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
      { headers: headers });
  }

  getAllDocuments(workspaceId: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents`);
  }

  getDocument(id: string, workspaceId: string): Observable<Document> {

    return this.http.get<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${id}`);
  }

  updateDocument(document: Document, workspaceId: string): Observable<Document> {

    return this.http.put<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
      document);
  }

  //
  // Records
  //

  createRecord(record: RecordContent, workspaceId: string): Observable<RecordDocument> {

    return this.http.post<RecordDocument>(
      environment.documentApiBaseUrl + `/${workspaceId}/records/${record.recordType}`, record);
  }

  deleteRecord(record: RecordDocument, workspaceId: string): Observable<RecordDocument> {
    const headers = new HttpHeaders()
      .set('If-Match', record.eTag ?? '');

    return this.http.delete<RecordDocument>(
      environment.documentApiBaseUrl + `/${workspaceId}/records/${record.recordType}/${record.id}`,
      { headers: headers });
  }

  getRecordsOfType(recordTypeName: string, workspaceId: string): Observable<RecordDocument[]> {

    return this.http.get<RecordDocument[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/records/${recordTypeName}`);
  }

  updateRecord(id: string, workspaceId: string, record: RecordContent): Observable<RecordDocument> {

    return this.http.put<RecordDocument>(
      environment.documentApiBaseUrl + `/${workspaceId}/records/${record.recordType}/${id}`,
      record);
  }


  //
  // Reference Data
  //

  getReferenceData(workspaceId: string): Observable<ReferenceDataContent[]> {

    return this.http.get<ReferenceDataContent[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents?documentType=referenceData`);
  }


  //
  // Translation
  //

  createTranslation(translation: TranslationContent, workspaceId: string): Observable<any> {

    return this.http.post<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/`, <Document>{
        documentType: DocumentTypes.translation,
        content: translation
      });
  }

  //
  // Workspace Data
  //

  getWorkspaceData(workspaceId: string): Observable<Document> {

    return this.http.get<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/workspaceData`);
  }
}
