import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, flatMap, merge, mergeMap, of } from 'rxjs';

import { environment } from '@env';

import {
  MemberContent, SecurityEntitiesResponse, SecurityEntity, ServiceVersion, WorkspaceContent
} from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  private readonly postOptions = {
    observe: 'response' as 'response',
    responseType: 'json' as 'json',
  };

  constructor(private http: HttpClient) { }

  getVersion(): Observable<ServiceVersion> {
    return this.http.get<ServiceVersion>(environment.adminApiBaseUrl + '/health/version');
  }

  //
  // Workspaces
  //

  createWorkspace(content: WorkspaceContent): Observable<SecurityEntity<WorkspaceContent>> {
    return this.http.post<WorkspaceContent>(
      environment.adminApiBaseUrl + '/workspaces',
      content,
      this.postOptions)
      .pipe(
        mergeMap((response: HttpResponse<WorkspaceContent>, _) => {
          const id = response.headers.get('Location')?.split('/').pop();
          const content = response.body as WorkspaceContent;
          return of({ id, content } as SecurityEntity<WorkspaceContent>);
        })
      );
  }

  updateWorkspace(content: WorkspaceContent, id: string): Observable<Object> {
    return this.http.put(environment.adminApiBaseUrl + `/workspaces/${id}`, content);
  }

  getWorkspaces(): Observable<SecurityEntitiesResponse<WorkspaceContent>> {
    return this.http.get<SecurityEntitiesResponse<WorkspaceContent>>(
      environment.adminApiBaseUrl + '/workspaces');
  }

  getWorkspace(id: string): Observable<WorkspaceContent> {
    return this.http.get<WorkspaceContent>(environment.adminApiBaseUrl + `/workspaces/${id}`);
  }

  patchWorkspace(patch: any, id: string): Observable<WorkspaceContent> {
    return this.http.patch<WorkspaceContent>(
      environment.adminApiBaseUrl + `/workspaces/${id}`,
      patch);
  }

  //
  // Members
  //

  createMember(content: MemberContent): Observable<SecurityEntity<MemberContent>> {
    return this.http.post<MemberContent>(
      environment.adminApiBaseUrl + '/members',
      content,
      this.postOptions).pipe(
        mergeMap((response: HttpResponse<MemberContent>, _) => {
          const id = response.headers.get('Location')?.split('/').pop();
          const content = response.body as MemberContent;
          return of({ id, content } as SecurityEntity<MemberContent>);
        }
        )
      );
  }

  updateMember(content: MemberContent, id: string): Observable<MemberContent> {
    return this.http.put<MemberContent>(environment.adminApiBaseUrl + `/members/${id}`, content);
  }

  getMembers(): Observable<SecurityEntitiesResponse<MemberContent>> {
    return this.http.get<SecurityEntitiesResponse<MemberContent>>(
      environment.adminApiBaseUrl + '/members');
  }

  getMember(id: string): Observable<MemberContent> {
    return this.http.get<MemberContent>(environment.adminApiBaseUrl + `/members/${id}`);
  }

  patchMember(patch: any, id: string): Observable<MemberContent> {
    return this.http.patch<MemberContent>(
      environment.adminApiBaseUrl + `/members/${id}`,
      patch);
  }
}
