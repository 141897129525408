import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IdentityService, StorageService } from '@core/services';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isAuthenticated = false;
  isSuperAdmin = false;
  canViewMembers = false;
  canViewWorkspaces = false;

  constructor(
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    private identityService: IdentityService,
    private readonly router: Router,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (!this.identityService.isAuthenticated) {
          this.authService.login();
        }
      });

    this.identityService.claimsChanged$.subscribe(() => {
      this.isAuthenticated = this.identityService.isAuthenticated;
      this.isSuperAdmin = this.identityService.isSuperAdmin;
      if (this.isAuthenticated) {
        this.canViewMembers = this.identityService.isSuperAdmin;
        this.canViewWorkspaces = this.identityService.isSuperAdmin || this.identityService.isAdmin;
      } else {
        this.canViewMembers = false;
        this.canViewWorkspaces = false;
      }
    });
  }

  onLogin(): void {
    this.msalService.loginRedirect();
  }

  onNavigateToWorkspaces(): void {
    this.storageService.set('viewingBin', false);
    this.router.navigate(['/workspaces']);
  }
}
