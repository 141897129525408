import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

export class Toast {
  constructor (private ref: MatSnackBarRef<TextOnlySnackBar>) {}

  close() {
    this.ref.dismiss();
  }
}


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  open(message: string): Toast {
    return new Toast(this.snackBar.open(message));
  }

  pop(message: string): void {
    this.snackBar.open(message, undefined, { duration: 6000 });
  }
}
