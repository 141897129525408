import { AuthApiService } from './auth-api.service';
import { AuthService } from './auth.service';
import { AdminApiService } from './admin-api.service';
import { DialogService } from './dialog.service';
import { DocumentApiService } from './document-api.service';
import { IdentityService } from './identity.service';
import { LibraryService } from './library.service';
import { MonacoEditorService } from './monaco-editor.service';
import { StorageService } from './storage.service';
import { ToastService } from './toast.service';
import { ValidationService } from './validation.service';
import { UserMemberService } from './user-member.service';

export const services: any[] = [
  AdminApiService,
  AuthApiService,
  AuthService,
  DialogService,
  DocumentApiService,
  IdentityService,
  LibraryService,
  MonacoEditorService,
  StorageService,
  ToastService,
  ValidationService,
  UserMemberService
];

export * from './admin-api.service';
export * from './auth-api.service';
export * from './auth.service';
export * from './dialog.service';
export * from './document-api.service';
export * from './identity.service';
export * from './library.service';
export * from './monaco-editor.service';
export * from './storage.service';
export * from './toast.service';
export * from './validation.service';
export * from './user-member.service';
